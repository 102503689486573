import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { Modal } from 'pharmacy/src/display/modal'
import { Button } from 'pharmacy/src/input/button'
import { Body1, Header1, Header3 } from 'pharmacy/src/typography'

import { SPLIT_DIGEST_COHORT_MODAL } from 'mednet-util/src/constants/modal'

import { splitIntoTwoCohorts } from 'mednet-cns/src/reducers/digest'

import css from './splitCohortModal.scss'

const SPLIT_METHODS = {
  RANDOM: 'random',
  PARITY: 'parity',
}

function SplitCohortScreen({ closeModal, digestId, callback }) {
  const dispatch = useDispatch()

  const [splitMethod, setSplitMethod] = React.useState(SPLIT_METHODS.PARITY)

  const submit = useCallback(() => {
    dispatch(splitIntoTwoCohorts(digestId, splitMethod, callback))
    closeModal()
  }, [closeModal, dispatch, splitMethod, digestId, callback])

  return (
    <div className={css.container}>
      <Header1>
        Please choose how you would like to split the users into two cohorts
      </Header1>

      <hr />

      <div className={css.content}>
        <Header3
          className={css.option}
          onClick={() => setSplitMethod(SPLIT_METHODS.RANDOM)}
        >
          <input
            type="radio"
            checked={splitMethod === SPLIT_METHODS.RANDOM}
            onChange={() => setSplitMethod(SPLIT_METHODS.RANDOM)}
          />
          Randomize the users before splitting them
        </Header3>

        <Body1 className={css.optionDetails}>
          This method ensures that the results are not tied to specific user
          characteristics and can provide a more generalizable outcome.
          <br />
          Randomizing users can help eliminate biases and make the results more
          applicable to a broader audience.
        </Body1>

        <Header3
          className={css.option}
          onClick={() => setSplitMethod(SPLIT_METHODS.PARITY)}
        >
          <input
            type="radio"
            checked={splitMethod === SPLIT_METHODS.PARITY}
            onChange={() => setSplitMethod(SPLIT_METHODS.PARITY)}
          />
          Split the users by their ID parity (odd / even)
        </Header3>
        <Body1 className={css.optionDetails}>
          This approach allows you to run additional tests on the same groups
          later, ensuring that the same group of people is used consistently
          across different experiments. This can help you observe changes in
          behavior within the same cohort based on different variables.
          <br />
          Splitting by ID parity can facilitate longitudinal studies and
          comparisons within a fixed user group.
        </Body1>
      </div>
      <div className={css.buttonsConatiner}>
        <Button onClick={closeModal} className={css.button} type="neutral">
          Cancel
        </Button>
        <Button onClick={submit} className={css.button}>
          Split
        </Button>
      </div>
    </div>
  )
}

export default function SplitCohortModal(props) {
  return (
    <Modal modalId={SPLIT_DIGEST_COHORT_MODAL.modalId} {...props}>
      <SplitCohortScreen />
    </Modal>
  )
}
