import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'

import { openModal } from 'mednet-cns/src/reducers/modal'

import { actionIdByName } from 'mednet-util/src/constants/questionUpdate'
import { QUESTION_SHORT_TITLE_MODAL } from 'mednet-util/src/constants/modal'

import {
  Body1,
  Header2,
  Header3,
  Subtitle1,
  Subtitle2,
  Subtitle3,
} from 'pharmacy/src/typography'
import { Card } from 'pharmacy/src/display/card'
import { Button } from 'pharmacy/src/input/button'
import { UserUpdateText } from 'pharmacy/src/user/userUpdateInformation'
import { QuestionLink } from 'pharmacy/src/navigation/questionLink'
import { SimpleSelect } from 'pharmacy/src/input/select'
import { SimpleDropdownIndicator } from 'pharmacy/src/input/select/components'
import { QuestionPoll } from 'pharmacy/src/question/questionPoll'

import { IconWithPopup } from 'pharmacy/src/display/iconWithPopup'

import { fetchViewsFromSpecialty } from 'mednet-cns/src/reducers/question'

import { Icon } from 'pharmacy/src/display/icon'

import { LastUsedInfo, INFO_TYPES } from '../lastUsedInfo'
import { QuestionTopicTags } from '../questionTopicTags'

import css from './questionUpdateCard.scss'

class QuestionUpdateCard extends React.Component {
  state = {
    showMore: false,
  }

  toggleMore = () => {
    const { question, specialtyId, fetchViewsFromSpecialty } = this.props

    this.setState((state) => ({
      showMore: !state.showMore,
    }))

    if (
      !question.views ||
      (!question.views[specialtyId] && question.views[specialtyId] !== 0)
    ) {
      fetchViewsFromSpecialty(question.questionId, specialtyId)
    }
  }

  handleAddUpdate = (questionUpdateId, questionId) => () => {
    const { onAddUpdate } = this.props
    if (onAddUpdate) {
      onAddUpdate(questionUpdateId, questionId)
    }
  }

  handleRemoveUpdate = (questionUpdateId, questionId) => () => {
    const { onRemoveUpdate } = this.props
    if (onRemoveUpdate) {
      onRemoveUpdate(questionUpdateId, questionId)
    }
  }

  handleMoveDown = () => {
    const { onMoveDown, question } = this.props
    onMoveDown && onMoveDown(question.questionId)
  }

  handleMoveUp = () => {
    const { onMoveUp, question } = this.props
    onMoveUp && onMoveUp(question.questionId)
  }

  handleRemoveQuestion = () => {
    const { onRemoveQuestion, question } = this.props
    if (onRemoveQuestion) {
      onRemoveQuestion(question.questionId)
    }
  }

  handleQuestionTitle = (option) => {
    const { openShortQuestionTitleModal } = this.props

    if (option.value === 'action') {
      openShortQuestionTitleModal()
    }
  }

  handleTopicClick = (topicId) => {
    const { onTopicClick } = this.props

    onTopicClick(topicId)
  }

  renderViews = () => {
    const { question, specialtyId, specialtyTitle } = this.props

    if (
      !question.views ||
      (!question.views[specialtyId] && question.views[specialtyId] !== 0)
    )
      return null

    return (
      <div className={css.stats}>
        <Subtitle2>{`Unique views in ${specialtyTitle}: ${question.views[specialtyId]}`}</Subtitle2>
        <Subtitle2>{`Unique views in total: ${question.views.total}`}</Subtitle2>
      </div>
    )
  }

  renderAddUpdateButton = (questionUpdateId) => {
    const { question } = this.props
    return (
      <Button
        className={css.addButton}
        size="small"
        icon={['fal', 'plus']}
        onClick={this.handleAddUpdate(questionUpdateId, question.questionId)}
      ></Button>
    )
  }

  renderRemoveUpdateButton = (questionUpdateId, force) => {
    const { updates, question } = this.props
    return !force && (!updates || updates.length < 2) ? null : (
      <Button
        className={css.removeUpdateButton}
        type="destructive"
        icon={['far', 'trash-alt']}
        onClick={this.handleRemoveUpdate(questionUpdateId, question.questionId)}
      />
    )
  }

  renderAnswer(update) {
    if (!update || !update.answer) return null

    const { specialtyId } = this.props
    const { showMore } = this.state
    const answer = update.answer
    const text = answer.answer
    const trimmedBody =
      text.length > 150 ? `${text.substring(0, 149)}...` : text
    const body = showMore ? text : trimmedBody

    return (
      <>
        {!showMore ? null : (
          <LastUsedInfo
            updateId={update.questionUpdateId}
            type={INFO_TYPES.UPDATE}
            className={css.lastUsedInfo}
            mode="dailydigest"
            specialtyId={specialtyId}
          />
        )}
        <div className={css.header}>
          {this.renderRemoveUpdateButton(update.questionUpdateId)}
          <UserUpdateText
            userId={answer.user.userId}
            user={answer.user}
            dateUpdated={answer.created}
            actionId={update.actionId}
          />
        </div>
        <Body1
          className={css.updateBodyStandard}
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        ></Body1>
      </>
    )
  }

  renderAnswers() {
    const { updates } = this.props

    const answerUpdates = updates.filter(
      (update) =>
        update.actionId === actionIdByName.ANSWER ||
        update.actionId === actionIdByName.ANSWER_UPDATED
    )

    return answerUpdates.map((update) => (
      <div className={css.update} key={update.questionUpdateId}>
        {this.renderAnswer(update)}
      </div>
    ))
  }

  renderPolls() {
    const { question } = this.props
    const { updates } = question

    const pollUpdates = updates.filter(
      (update) => update.actionId === actionIdByName.POLL_APPROVED
    )

    return pollUpdates.map((update) => (
      <div className={css.update} key={update.questionUpdateId}>
        {this.renderPoll(update)}
      </div>
    ))
  }

  renderPoll(update) {
    const { updates, specialtyId } = this.props
    const { showMore } = this.state
    const poll = update.poll
    const text = poll.pollQuestion
    const trimmedBody =
      text.length > 150 ? `${text.substring(0, 149)}...` : text
    const body = showMore ? text : trimmedBody
    let isPollUsed = true

    if (
      !_.includes(
        updates.map((update) => update.questionUpdateId),
        update.questionUpdateId
      )
    )
      isPollUsed = false

    if (!isPollUsed && !showMore) return null

    const header = (
      <>
        {!showMore ? null : (
          <LastUsedInfo
            updateId={update.questionUpdateId}
            type={INFO_TYPES.UPDATE}
            className={css.lastUsedInfo}
            mode="dailydigest"
            specialtyId={specialtyId}
          />
        )}
        <div className={css.header}>
          {!isPollUsed
            ? this.renderAddUpdateButton(update.questionUpdateId)
            : this.renderRemoveUpdateButton(update.questionUpdateId, true)}
          <UserUpdateText
            userId={poll.user.userId}
            user={poll.user}
            dateUpdated={poll.created}
            actionId={update.actionId}
          />
        </div>
      </>
    )

    if (!showMore) {
      return (
        <>
          {header}
          <Body1
            className={css.updateBodyStandard}
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          ></Body1>
        </>
      )
    }

    poll.showResults = true
    poll.showVote = false

    return (
      <>
        {header}
        <QuestionPoll
          questionId={update.questionId}
          poll={poll}
          className={css.poll}
          HeaderComponent={Header3}
          hideHeaderInfo
          hideExportToCSV
        />
      </>
    )
  }

  renderUpdates() {
    const { updates } = this.props
    if (!updates || !updates.length) return null

    return (
      <div className={css.updates}>
        <div className={css.answers}>{this.renderAnswers()}</div>
        <div className={css.polls}>{this.renderPolls()}</div>
      </div>
    )
  }

  render() {
    const {
      question,
      updates,
      className,
      primaryTopic,
      moveButtonsHidden,
      dragHandleProps,
      specialtyId,
    } = this.props
    const updateIds = updates.map((update) => update.questionUpdateId)
    const { showMore } = this.state

    const answerUpdates = question.updates.filter(
      (update) => update.actionId === actionIdByName.ANSWER
    )
    const pollUpdates = question.updates.filter(
      (update) => update.actionId === actionIdByName.POLL_APPROVED
    )

    const answers = {
      total: answerUpdates.length,
      used: answerUpdates.filter((update) =>
        _.includes(updateIds, update.questionUpdateId)
      ).length,
    }

    const polls = {
      total: pollUpdates.length,
      used: pollUpdates.filter((update) =>
        _.includes(updateIds, update.questionUpdateId)
      ).length,
    }

    const title = {
      label: question.short ? question.short : question.question,
      value: 'title',
    }
    const full = {
      label: `Full question: ${question.question}`,
      value: 'full',
    }
    const action = {
      label: question.short
        ? 'Click here to edit short version of question...'
        : 'Click here to add short version of question...',
      value: 'action',
    }

    const renderWarningIcon = title.label.length > 90
    const activeSponsorships = question.sponsorships?.filter((sponsorship) =>
      moment(new Date()).isBetween(sponsorship.startDate, sponsorship.endDate)
    )

    return (
      <Card
        className={classNames({
          [css.card]: true,
          [css.updateCard]: true,
          [className]: true,
          [css.sponsored]: Boolean(activeSponsorships?.length),
        })}
      >
        <div className={css.cardHeader}>
          <div className={css.dragHandler} {...dragHandleProps}>
            <Icon className={css.grip} icon={['far', 'grip-horizontal']} />
          </div>
          <Subtitle1>
            {answers.total === 0
              ? null
              : `${answers.used} answer${answers.used > 1 ? 's' : ''} [${
                  answers.total
                }] `}
            {polls.total === 0
              ? null
              : `${polls.used} poll${polls.used > 1 ? 's' : ''} [${
                  polls.total
                }]`}
          </Subtitle1>
          <div className={css.buttons}>
            {moveButtonsHidden ? null : (
              <Button
                className={classNames(css.moveButton, 'moveUpdateDown')}
                icon={['far', 'angle-down']}
                onClick={this.handleMoveDown}
              />
            )}
            {moveButtonsHidden ? null : (
              <Button
                className={classNames(css.moveButton, 'moveUpdateUp')}
                icon={['far', 'angle-up']}
                onClick={this.handleMoveUp}
              />
            )}
            <Button
              className={classNames(css.removeButton)}
              type="destructive"
              icon={['far', 'trash-alt']}
              onClick={this.handleRemoveQuestion}
            />
          </div>
        </div>
        <div className={css.question}>
          <div className={css.meat}>
            <div className={classNames(css.lastUsedInfo)}>
              <LastUsedInfo
                questionId={question.questionId}
                type={INFO_TYPES.QUESTION}
                mode="dailydigest"
                specialtyId={specialtyId}
              />
            </div>
            <div className={classNames(css.questionTitle)}>
              {!renderWarningIcon ? null : (
                <IconWithPopup className={css.warningIcon} type="warning">
                  {question.short
                    ? 'Question title is over 90 characters long, consider a new short version'
                    : 'Question does not have a short title specified, consider adding short version with less than 90 characters'}
                </IconWithPopup>
              )}
              <SimpleSelect
                options={[full, action]}
                defaultValue={title}
                value={title}
                onChange={this.handleQuestionTitle}
                components={{
                  SingleValue: ({ children }) => (
                    <QuestionLink
                      className={css.questionLink}
                      questionId={question.questionId}
                      target="_blank"
                    >
                      <Header2>{children}</Header2>
                    </QuestionLink>
                  ),
                  DropdownIndicator: SimpleDropdownIndicator,
                }}
              />
            </div>
            <QuestionTopicTags
              topics={question.topics}
              primary={primaryTopic}
              onTopicClick={this.handleTopicClick}
            ></QuestionTopicTags>
            {!showMore ? null : this.renderViews()}
          </div>
        </div>
        {this.renderUpdates()}
        <div className={classNames(css.showMore)}>
          <Subtitle3>
            <Button
              type="secondaryText"
              size="medium"
              onClick={this.toggleMore}
            >
              {showMore ? 'show less' : 'show more'}
            </Button>
          </Subtitle3>
        </div>
      </Card>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchViewsFromSpecialty: (questionId, specialtyId) =>
    dispatch(fetchViewsFromSpecialty(questionId, specialtyId)),
  openShortQuestionTitleModal: () =>
    dispatch(
      openModal(QUESTION_SHORT_TITLE_MODAL.modalId, {
        question: ownProps.question,
      })
    ),
})

export default connect(null, mapDispatchToProps)(QuestionUpdateCard)
